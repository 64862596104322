import { ComponentProps } from "react"
import { AutocompleteInput, ReferenceInput } from "react-admin"
import { NIL } from "uuid"

export const ProjectInput = ({
  organization_id,
  source = "project_id",
  ...props
}: {
  organization_id: string
} & ComponentProps<typeof AutocompleteInput>) => (
  <ReferenceInput
    reference="projects"
    source={source}
    sort={{ field: "updated_at", order: "DESC" }}
    perPage={100}
    filter={{
      "organization_id@in": [NIL, organization_id],
      "deleted_at@is": null,
    }}
  >
    <AutocompleteInput
      fullWidth
      source="project_name"
      optionText="project_name"
      filterToQuery={(query) => ({
        "project_name@ilike": query,
      })}
      {...props}
    />
  </ReferenceInput>
)
